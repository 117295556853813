.App {
  text-align: center;
}

.App-logo {
  width: 70%;
  pointer-events: none;
}

.App-header {
  background-color: #FFFFFF;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-footer {
  font-size: 3vmin;
}
