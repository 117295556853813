.list {
    border: 2px solid #dee2e6;
    border-radius: 12px;
    width: 70vmin;
    background: linear-gradient(white, #dee2e6);
}

.row-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #007bff;
    border-radius: 10px 10px 0px 0px;
    height: 10vmin;
    font-weight: bold;
    color: white;
    font-size: 5vmin;
}

.row-border {
    border-bottom: 2px solid #dee2e6;
}

.row-group {
    display: flex;
    align-items: center;
    height: 15vmin;
}

.row-group .item {
    flex: 1;
    font-weight: bold;
    padding: 5%;
}

.row-group .title {
    font-size: 5vmin;
}

.row-group .kindof {
    color: #383838;
    font-size: 5vmin;
}

.row-group .number {
    color: #3273c3;
    font-size: 7vmin;
}